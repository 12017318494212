// LazyLoad
import lazyLoadInit from "./lazyload-init";
import Swiper from "swiper/bundle";
import "jquery.marquee";

// pushbar
lazyLoadInit();
function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

// access button link Orange confort

jQuery().ready(() => {
  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });
  jQuery(".header__flash-infos .ticker").marquee({
    speed: 90,
    gap: 30,
    delayBeforeStart: 0,
    direction: "left",
    duplicated: false,
    pauseOnHover: true,
    startVisible: false,
  });

  var bandeau_slider = new Swiper(".bandeau-slider", {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  var demarches_slider = new Swiper(".unclic", {
    slidesPerView: 6,
    loop: true,

    // Navigation arrows
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },
    // responsive
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      470: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    },
  });

  console.log(demarches_slider);

  // .js-demarches on click slidetoggle #unclic
  jQuery(".js-demarches").on("click", function () {
    jQuery("#unclic").slideToggle();
    jQuery(this).toggleClass("hide");
    // demarches_slider.update();
    demarches_slider.update();
  });

  var swiperLeft = new Swiper(".swiper-left", {
    effect: "coverflow",
    grabCursor: true,
    mousewheel: true,
    centeredSlides: true,
    initialSlide: 1,
    slidesPerView: 3,
    slideToClickedSlide: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 200,
      modifier: 1,
      slideShadows: false,
    },
  });

  var swiperRight = new Swiper(".swiper-right", {
    effect: "fade",
    initialSlide: 1,
  });

  swiperLeft.on("slideChange", function () {
    swiperRight.slideTo(swiperLeft.activeIndex);
  });

  jQuery(".js-search, .js-search-close").on("click", function () {
    jQuery(".js-search-overlay").toggleClass("active");

    // fidn input focus but no .focus() method because it's deprecated
    jQuery(".js-search-overlay input").focus();
  });
  // if it escape key remove class active
  jQuery(document).keyup(function (e) {
    if (e.key === "Escape") {
      // if .js-search-overlay has class active remove it
      if (jQuery(".js-search-overlay").hasClass("active")) {
        jQuery(".js-search-overlay").removeClass("active");
      }
    }
  });

  jQuery("#js-menu-open, #js-menu-close").on("click", function () {
    jQuery("#js-menu").toggleClass("active");
    jQuery("#js-menu-close").toggleClass("hidden");
    jQuery("#js-menu-open").toggleClass("hidden");
    // add stycky class to .menu-container
    jQuery(".menu-container").toggleClass("sticky");
    // add overflowHidden to body
    jQuery("body").toggleClass("overflowHidden");
  });

  // on click js-responsive-open-menu open ul sibling
  jQuery(".responsive-menu__link").on("click", function () {
    jQuery(this).siblings("ul").slideToggle();
    //parent add class active
    jQuery(this).parent().toggleClass("active");
  });

  // click .niv_2 slideToggle sibling ul
  jQuery(".niv_2").on("click", function () {
    jQuery(this).parent().parent().find("ul").slideToggle();
    jQuery(this).parent().parent().toggleClass("active");
    console.log(jQuery(this).parent().parent());
  });

  // When the user scrolls the page, execute myFunction
  window.onscroll = function () {
    myFunction();
  };

  // Get the navbar
  var navbar = document.getElementById("navbar");

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;

  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function myFunction() {
    if (window.scrollY >= sticky && window.innerWidth > 1024) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  }
});
